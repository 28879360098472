#App {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;

}

.App-logo {
  height: 40vmin;
}

#App-header {
  background-color: #282c34;
  min-height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  text-align: left;
  padding: 0 16px 0 16px;

  color: white;
}

#App-body {
  min-height: 73vh;
  overflow-x: hidden;
  text-align: left;
 /* padding: 12px; */
}

#App-bar-wrapper {
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: left;


  font-size: calc(6px + 2vmin);
  text-align: left;
}

#App-footer {
  min-height: 8vh;

  background-color:  #282c34;
  color: white;
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
 
  padding: 0 16px 0 16px;
}

.App-link {
  color: #09d3ac;
}
.Mui-selected {
  color: white !important;
  border-bottom: 2px solid red;
}
